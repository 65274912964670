<template>
  <div class="videobox">
    <iframe :src="src" ref="iframe" height="100%" width="100%" frameborder="0">
    </iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      src: "",
      username: "",
      password: "",
      mac: ""
    };
  },
  mounted() {
    this.geturl();
    this.changesrc();
  },
  // http://10.10.18.94:8080/#/loginByToken?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwIjoiIiwiYSI6ImFkbWluIiwiaXNzIjoieG1leWUubmV0In0.KWeBhrPUKk7-Jz-6oOTjW7EOkcKFqRXKEDuUbKeQLCI&uuid=b11ffe8d6b812473
  methods: {
    changesrc() {
      this.src = "/video2/index.htm?r=" + Math.random();
    },

    setParameter() {
      window.videoInfo = () => {
        return {
          username: this.username,
          password: this.password,
          mac: this.mac,
          lang: 101
        };
      };
    },
    geturl() {
      let str = location.href;
      let arr = str.split("=");
      let token = arr[1].split("&")[0];
      let uuid = arr[2];
      this.$http
        .get(
          "http://10.10.18.55:8081/loginByToken?token=" +
            token +
            "&uuid=" +
            uuid
        )
        .then(res => {
          this.username = res.data.uname;
          this.password = res.data.upass;
          this.mac = res.data.uuid;
        });
      this.setParameter();
    }
  }
};
</script>
<style scoped>
.videobox {
  width: 100vw;
  height: 100vh;
}
</style>
